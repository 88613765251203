import React from 'react';
import './Hero.css';
import { Link } from 'react-router-dom';

function Hero() {
  return (
    <section className="hero">
      <h1>WE ARE INTEGRA 3646</h1>
      <p>FIRST (C) Robotics Competition INTEGRA 3646 from Kemerburgaz Bahçeşehir Science and Technology High School, Istanbul/TÜRKİYE</p>
      <Link to="/about" className="hero-button">Learn More About Us</Link>
    </section>
  );
}

export default Hero;
