import React, { useRef } from 'react';
import './News.css';
import { Link, useLocation } from 'react-router-dom';

const newsArticles = [
  {
    title: 'Upcoming Event: Robotics Competition',
    date: 'October 30, 2024',
    image: '../photos/placeholder.jpg',
    content: 'Join us for the upcoming robotics competition where our team will showcase innovative projects and compete against others. Stay tuned for more details!',
  },
  {
    title: 'Team Workshop: Building Strategies',
    date: 'October 15, 2024',
    image: '../photos/placeholder.jpg',
    content: 'Our team participated in a workshop focused on building effective strategies for the upcoming season. Collaboration is key!',
  },
  {
    title: 'Community Outreach: Engaging the Youth',
    date: 'October 1, 2024',
    image: '../photos/placeholder.jpg',
    content: 'We engaged with local youth to inspire them to explore STEM fields through hands-on robotics activities. A great success!',
  },
  {
    title: 'New Partnership Announcement',
    date: 'September 28, 2024',
    image: '../photos/placeholder.jpg',
    content: 'We are excited to announce our new partnership with local tech companies to support our robotics programs.',
  },
  {
    title: 'Kickoff for the New Season',
    date: 'September 15, 2024',
    image: '../photos/placeholder.jpg',
    content: 'The new robotics season is starting! Join us for our kickoff event to learn more about our plans for the year.',
  },
];

function News({ articles = [] }) {

  const location = useLocation();
  const isMainPage = location.pathname === '/';

  const newsRef = useRef(null);

  return (
    <div className={`news ${isMainPage ? '' : 'no-background'}`} ref={newsRef}>
      
      <h1 className='fade-in'>Latest News</h1>

      <div className="news-list fade-in">
        {articles.map((article, index) => (
          <div key={index} className="news-card">
            <h3>{article.title}</h3>
            <p className="date">{article.date}</p>
            <p>{article.content}</p>
          </div>
        ))}
      </div>

      {location.pathname !== '/news' && (
        <div className="button-container">
          <Link to="/news" className='news-button'>View More News</Link>
        </div>
      )}
    </div>
  );
}


export { newsArticles };
export default News;
