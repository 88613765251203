import React from 'react';
import './Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faXTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import logo from '../../photos/logo.png';
import logo1 from '../../photos/logo1.png';
import { Link } from 'react-router-dom';

function Header() {

  return (
    <header className="header">
      <div className="logo">
        <img src={logo} alt="INTEGRA Logo" className="logo-image" />
        <img src={logo1} alt="INTEGRA 3646 Logo" className="logo-img" />
      </div>
      
      <nav className='texts'>
        <ul>
          <li><Link to="/" className="header-button">Home</Link></li>
          <Link to="/about" className="header-button hide-on-small">About Us</Link>
          <Link to="/awards" className="header-button hide-on-small">Awards</Link>
          <Link to="/news" className="header-button hide-on-small">News</Link>
          <Link to="/gallery" className="header-button hide-on-small">Gallery</Link>
        </ul>
      </nav>

      <nav className="social-medias">
        <ul>
          <li>
            <a href="https://x.com/frc_integra3646?s=21" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faXTwitter} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/integra_3646?igsh=MXM5bDdxN3BmZmpxeg==" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} />
            </a>
          </li>
          <li>
            <a href="https://youtube.com/@frc3646?si=2HDun_gC94_xgp5H" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
