// src/components/Footer.jsx
import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer" id="contact">
      <p>Contact us at: integra3646@gmail.com</p>
      <div className="social">
        <a href="https://youtube.com/@frc3646?si=2HDun_gC94_xgp5H" target="_blank" rel="noopener noreferrer">Youtube</a>
        <a href="https://x.com/frc_integra3646?s=21" target="_blank" rel="noopener noreferrer">Twitter/X</a>
        <a href="https://www.instagram.com/integra_3646?igsh=MXM5bDdxN3BmZmpxeg==" target="_blank" rel="noopener noreferrer">Instagram</a>
      </div>
    </footer>
  );
}

export default Footer;
