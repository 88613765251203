import React from 'react';
import './AwardsPage.css';

const awardsList = [
  { year: 2011, awards: ["Jury Prize"] },
  { year: 2012, awards: ["4th Alliance in Robot Performance"] },
  { year: 2014, awards: ["7th Alliance in Robot Performance"] },
  { 
    year: 2015, 
    awards: [
      "Chrysler-sponsored Spirit Team Award",
      "5th Alliance in Robot Performance",
      "Turkish Robotics Off-Season Winning Alliance (as Alliance Captain)",
      "Engineering Inspiration",
      "Security Animation",
      "Woodie Flowers"
    ]
  },
  { year: 2016, awards: ["Beta Testing Team", "New York Regional First Impact"] },
  { year: 2017, awards: ["Orange County Regional First Impact", "FIRST Championship (Houston)", "First Impact Finalist"] },
  { 
    year: 2018, 
    awards: [
      "SBPLI Long Island Regional #1 Winning Alliance (as Alliance Captain)",
      "FIRST Championship (Houston) Semi-Finalist"
    ] 
  },
  { 
    year: 2019, 
    awards: [
      "Istanbul Regional First Impact",
      "Bosphorus Regional Woodie Flowers Finalist Award",
      "Ford Sponsored Bosphorus Regional Autonomous Award",
      "Ford Sponsored SBPLI Long Island Regional #1 Autonomous Award",
      "SBPLI Long Island Regional Finalist",
      "Galileo Semi-Finalist Winner"
    ] 
  },
  { 
    year: 2020, 
    awards: [
      "FIRST Dean's List Finalist Award (Boran Ocak)",
      "Bosphorus Regional Winner"
    ] 
  },
  { year: 2021, awards: ["Infinite Recharge At Home Challenge Quality Award"] }
];

function AwardsPage() {
  return (
    <section className="awards-page">
      <h1 className='our-awards fade-in'>Our Awards</h1>
      <div className="awards-container fade-in">
        <div className="awards-list">
          {awardsList.map((item, index) => (
            <div key={index} className="award-section">
              <h2 className="award-year">{item.year}</h2>
              <ul className="award-items">
                {item.awards.map((award, idx) => (
                  <li key={idx} className="award-item">{award}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default AwardsPage;
