import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header/Header';
import Hero from './components/Hero/Hero';
import Awards from './components/Awards/Awards';
import Footer from './components/Footer/Footer';
import AboutUs from './components/AboutUs/AboutUs';
import News, { newsArticles } from './components/News/News';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import AwardsPage from './components/AwardsPage/AwardsPage';


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function FadeInWrapper() {
  const location = useLocation();

  useEffect(() => {
    const elements = document.querySelectorAll('.fade-in');
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          observer.unobserve(entry.target);
        }
      });
    }, { threshold: 0.1 });

    elements.forEach((element) => observer.observe(element));

    return () => observer.disconnect();
  }, [location]);

  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="fade-in"><Hero /></div>
              <div className="fade-in"><Awards /></div>
              <div className="video-container fade-in">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/QmLJNVWxOV8?si=gUc4ijFvkyKbUsah"
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
              <div className="fade-in">
                <News articles={newsArticles.slice(0, 4)} />
              </div>
            </>
          }
        />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/awards" element={<AwardsPage />} />
        <Route path="/news" element={<News articles={newsArticles} />} />
      </Routes>
    </>
  );
}

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <ScrollToTop/>
        <FadeInWrapper />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
