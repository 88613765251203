import React from 'react';
import './Awards.css';
import { Link } from 'react-router-dom';

function Awards() {
  return (
    <section className="awards" id="about">
      <h1>Our Awards</h1>
      <ul className="awards-list">
        <li>Total Bluebanner Flag: 9</li>
        <li>First Impact Finalist: 2</li>
        <li>First Impact: 3</li>
        <li>Winners: 3</li>
        <li>Woodie Flowers Finalist Award: 1</li>
      </ul>
      <Link to="/awards" className="awards-button">See All Awards</Link>
    </section>
  );
}

export default Awards;
